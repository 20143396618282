.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  /* Edge用 */
  -ms-overflow-style: none;
  /* Firefox用 */
  scrollbar-width: none;
}

/* ChromeとSafari用 */
body::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

body.no_scroll,
.no_scroll_backdrop {
  overflow: hidden;
}

a:focus {
  outline: none;
}

.__body {
  animation: shake 0.2s ease-in-out infinite alternate;
}

.__line {
  transform-origin: center right;
  stroke-dasharray: 22;
  animation: line 0.8s ease-in-out infinite;
  animation-fill-mode: both;
}

.--top {
  animation-delay: 0s;
}

.--middle {
  animation-delay: 0.2s;
}

.--bottom {
  animation-delay: 0.4s;
}

@keyframes shake {
  0% {
    transform: translateY(-1%);
  }

  100% {
    transform: translateY(3%);
  }
}

@keyframes line {
  0% {
    stroke-dashoffset: 22;
  }

  25% {
    stroke-dashoffset: 22;
  }

  50% {
    stroke-dashoffset: 0;
  }

  51% {
    stroke-dashoffset: 0;
  }

  80% {
    stroke-dashoffset: -22;
  }

  100% {
    stroke-dashoffset: -22;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem
  }
}

@keyframes floating-y {
  0% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(30%);
  }
}

@keyframes floating-right-x {
  0% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(30%);
  }
}

@keyframes floating-left-x {
  0% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(-30%);
  }
}

.ico-arrow-right {
  animation: floating-right-x 1.8s ease-in-out infinite alternate-reverse;
}

.ico-arrow-left {
  animation: floating-left-x 1.8s ease-in-out infinite alternate-reverse;
}

.news-item p {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.news-title p {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.news-title-home p {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.news-image {
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}

.news-image-box {
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
}